<template>
  <span v-if="inline" :class="classes">{{ text }}</span>
  <div v-else :class="classes">{{ text }}</div>
</template>

<script>
import './text.scss';

export default {
  name: 'RText',

  props: {
    text: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: 'dark'
    },
    inline: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: ''
    },
    bold: {
      type: Boolean,
      default: false
    },
    addclasses: {
      type: String,
      required: false,
    }
  },

  computed: {
    classes() {
      return {
        'r-text': true,
        [`c-${this.color}`]: this.color,
        [`${this.size}`]: true,
        'bold': this.bold,
        [`${this.addclasses}`]: this.addclasses,

      };
    },
  },
};
</script>
