<template>
  <button :type="type" :class="classes" @click="handleClick">
    {{ label }}
  </button>
</template>

<script>
import "./button.scss";

export default {
  name: "RButton",

  props: {
    addclasses: {
      type: String,
      required: false,
    },
    onClick: {
      type: Function,
      required: false,
    },
    label: {
      type: String,
      required: true,
    },
    main: {
      type: Boolean,
      default: false,
    },
    submit: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      validator: function (value) {
        return ["small", "medium", "large"].indexOf(value) !== -1;
      },
    },
  },

  computed: {
    classes() {
      return {
        "r-btn": true,
        "c-white": this.main,
        "c-secondary": !this.main,
        [`${this.size || "medium"}`]: true,
        [`${this.addclasses}`]: this.addclasses,
      };
    },
    type() {
      return this.submit ? "submit" : "button"
    }
  },
  methods: {
    handleClick() {
      this.onClick()
    }
  }
};
</script>
