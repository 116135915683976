<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "App",
  metaInfo: {
    title: "Лицензии",
  },

  components: {},

  computed: mapState({
    user: (state) => state.user.info,
  }),

  watch: {
    user: {
      deep: true,
      handler: function (newVal, oldVal) {

        if (newVal && !oldVal) {
          this.$router.push("/dashboard");
        }
        if (newVal === null) {
          this.$router.push("/login");
        }
      },
    },
  },

  data: () => ({
    //
  }),
};
</script>
