<template>
  <div class="login">
    <div class="login-bg">
      <v-img
        :src="require('../../assets/logo-one-letter.svg')"
        contain
        height="280"
      />
    </div>
    <div class="login-form">
      <div class="wrap px-6">
        <v-img
          :src="require('../../assets/rosplatforma.svg')"
          contain
          height="72"
        />
        <form @submit.prevent="login">
          <r-text color="muted" text="Логин" addclasses="fs-2 mt-4" />
          <v-text-field v-model="username" outlined></v-text-field>
          <div class="d-flex justify-space-between">
            <r-text color="muted" text="Пароль" addclasses="fs-2" />
            <!-- <router-link class="restore-link" to="/restore-password">Восстановить пароль</router-link> -->
          </div>
          <v-text-field
            v-model="password"
            type="password"
            outlined
          ></v-text-field>
          <div class="d-flex">
            <RButton
              
              addclasses="c-bg-secondary"
              label="Войти"
              main
              size="small"
              submit
            />
            <div class="d-flex align-center">
              <r-text color="dark-active" text="Нет аккаунта? " addclasses="mr-2 ml-4" />

              <router-link class="register-link" to="/registration">Зарегистрируйтесь</router-link>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
// import "./button.scss";
import { mapActions } from "vuex";

import RText from "../ui/Text/Text.vue";
import RButton from "../ui/Button/Button.vue";

export default {
  name: "Login",
  components: { RText, RButton },
  props: {},
  computed: {
    username: {
      get() {
        return this.$store.state.username;
      },
      set(value) {
        this.$store.commit("user/updateUsername", value);
      },
    },

    password: {
      get() {
        return this.$store.state.password;
      },
      set(value) {
        this.$store.commit("user/updatePassword", value);
      },
    },
  },
  methods: {
    ...mapActions("user", ["login"]),
  },
};
</script>
<style>
.login {
  display: grid;
  grid-template-columns: 1fr 512px;
  grid-template-rows: 1fr;
  gap: 0px 0px;
}

.login-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.login-form {
  box-shadow: 0px 16px 48px rgba(0, 0, 0, 0.176);
  background: var(--light);
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.wrap {
  width: 100%;
}

.restore-link {
  color: #717378 !important;
}

.register-link {
  color: #2d3138 !important;
}
</style>