import Vue from 'vue'

import VueCookies from 'vue-cookies'
import Vuex, { createLogger } from 'vuex'
import user from './modules/user'
import licensions from './modules/licensions'

Vue.use(Vuex)
Vue.use(VueCookies)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    user,
    licensions
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
})