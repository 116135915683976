import api from '../../api'

// initial state
const state = () => ({
  list: [],
  currentLicense: null,
  licId: null,
})

// getters
const getters = {}

// actions
const actions = {
  async getList ({ commit }) {
    const licensionsResponse = await api.getLicensionsList()
    commit('setList', licensionsResponse.payload)
  },
  async getLicense({ commit, state}) {
    const licResponce = await api.getLicenseInfo(state.licId)
    commit('setLic', licResponce.payload)
  }
}

// mutations
const mutations = {
  setList (state, licensions) {
    state.list = licensions
  },
  setLicId (state, id) {
    state.licId = id
  },
  setLic (state, lic) {
    state.currentLicense = lic
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}