<template>
  <div class="wrapper">
    <div class="header">
      <v-container>
        <img :src="require('../../assets/rosplatforma.svg')" height="46" />

        <button class="link" @click="login">Войти</button>
      </v-container>
    </div>
    <div class="app-body">
      <v-container>
        <form class="register-form" @submit.prevent="registration">
          <r-text color="dark" text="Регистрация" addclasses="fs-4" />
          <r-text
            color="dark-active"
            text="Персональная информация"
            addclasses="fs-2 mt-2"
          />
          <div class="first-row">
            <div>
              <r-text color="dark-active" text="Фамилия" addclasses="fs-2" />
              <v-text-field v-model="firstname" outlined></v-text-field>
            </div>
            <div>
              <r-text color="dark-active" text="Имя" addclasses="fs-2" />
              <v-text-field v-model="lastname" outlined></v-text-field>
            </div>
            <div>
              <r-text color="dark-active" text="Отчество" addclasses="fs-2" />
              <v-text-field v-model="patronimyc" outlined></v-text-field>
            </div>
          </div>
          <r-text color="dark-active" text="Должность" addclasses="fs-2" />
          <v-text-field v-model="position" outlined></v-text-field>
          <r-text color="dark-active" text="E-mail" addclasses="fs-2" />
          <v-text-field v-model="email" outlined></v-text-field>
          <r-text color="dark-active" text="Пароль" addclasses="fs-2" />
          <v-text-field
            v-model="password"
            type="password"
            outlined
          ></v-text-field>
          <r-text
            color="dark-active"
            text="Информация о компании"
            addclasses="fs-2 mt-4"
          />
          <r-text color="dark" text="ИНН" addclasses="fs-2" />
          <v-text-field
            v-model="inn"
            @input="checkInn($event)"
            outlined
          ></v-text-field>
          <div class="my-2" v-if="companyInfo !== null">{{ companyInfo }}</div>
          <r-text color="dark" text="Сайт" addclasses="fs-2" />
          <v-text-field v-model="url" outlined></v-text-field>
          <div class="d-flex">
            <RButton
              addclasses="c-bg-secondary"
              label="Зарегистрироваться"
              main
              size="small"
              submit
            />
          </div>
        </form>
        <v-snackbar v-model="snackbar">
          Регистрация завершена

          <template v-slot:action="{}">
            <button class="link mr-1" @click="login">Войти</button>
          </template>
        </v-snackbar>
      </v-container>
    </div>
    <footer class="footer">
      <div class="c-dark">© ООО Р-Платформа</div>
    </footer>
  </div>
</template>

<script>
import moment from "moment";
import RButton from "../ui/Button/Button.vue";
import RText from "../ui/Text/Text.vue";
import api from "../../api";

export default {
  name: "Registration",
  components: { RButton, RText },

  props: {},

  data: () => ({
    firstname: "",
    lastname: "",
    patronimyc: "",
    position: "",
    email: "",
    password: "",
    inn: "",
    url: "",
    companyInfo: null,
    snackbar: false,
  }),

  methods: {
    dateFormat(dateStr) {
      return moment(dateStr).format("DD-MM-YYYY");
    },

    close() {
      this.create = false;
    },
    async registration() {
      const extraAttrs = [
        {
          attr_name: "inn",
          attr_type: "string",
          attr_value: this.inn,
        },
      ];
      await api
        .register({
          email: this.email,
          password: this.password,
          name: `${this.firstname} ${this.lastname}`,
          extra_attrs: extraAttrs,
        })
        .then(() => {
          this.snackbar = true;
          setTimeout(() => {
            this.$router.push("/login");
          }, 3000);
        });
    },
    login() {
      this.$router.push("/login");
    },
    checkInn(e) {
      if (e.length === 10) {
        api.getCompanyInfo(e).then((res) => {
          const companyInfoString = `${res.value}, ${res.data.address.unrestricted_value}`;
          this.companyInfo = companyInfoString;
        });
      }
      if (e.length < 10) {
        this.companyInfo = null;
      }
    },
  },

  created() {},
};
</script>
<style>
.header div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 64px;
}

.footer {
  position: fixed;
  bottom: 0;
  text-align: center;
  width: 100%;
}

.header {
  background: white;
}

.vm-list {
  display: flex;
}

.wrap {
  width: 100%;
}

.app-body {
  background: var(--light);
  height: 100%;
  min-height: calc(100vh - 70px);
}

.link {
  color: var(--main);
}

.centered td {
  vertical-align: middle;
}

.status-column {
  text-align: center;
}
.nobg {
  background-color: transparent !important;
  border: thin solid rgba(0, 0, 0, 0.12);
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.active {
  text-decoration: underline;
  color: var(--info);
}

.lighter {
  font-weight: lighter;
}

.text-btn {
  text-transform: none;
}

.first-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 4px;
  grid-template-areas: ". . .";
}

.register-form {
  width: 465px;
}

.register-form .v-input__slot {
  min-height: 36px !important;
}
</style>