import Vue from 'vue'
import api from '../../api'

// initial state
const state = () => ({
  info: null,
  username: "",
  password: "",
})

// getters
const getters = {

}

// actions
const actions = {
  async login ({state, commit}) {
    const userData = await api.login({"username": state.username, "password": state.password})
    Vue.$cookies.set("token", userData.payload.token)
    commit('setUserInfo', userData.payload)
  },

  // async registration ({state}) {
  //   const extraAttrs = [
  //     {
  //       "attr_name": "inn",
  //       "attr_type": "string",
  //       "attr_value": state.inn
  //     },
  //   ]
  //   const userData = await api.register({"username": state.username, "password": state.password, "name": `${state.firstname} ${state.lastname}`, "extra_attrs": extraAttrs})
  //   console.log({userData})
  
  // },

  async logout ({ commit }) {
    commit('logOut')
  }

}

// mutations
const mutations = {
  setUserInfo (state, userInfo) {
    state.info = userInfo
  },
  logOut (state) {
    Vue.$cookies.remove("token")
    state.info = null
    state.username = ""
    state.password = ""
  },
  updateUsername (state, username) {
    state.username = username
  },

  updatePassword (state, password) {
    state.password = password
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}